import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
function getter(columns, headerRows, headerStyles) {
    return R.map(idx => R.map(column => {
        const relevantStyles = R.map(s => s.style, R.filter(style => style.matchesColumn(column) &&
            style.matchesRow(idx), headerStyles));
        return relevantStyles.length ? R.mergeAll(relevantStyles) : undefined;
    }, columns), R.range(0, headerRows));
}
export default memoizeOneFactory(getter);
