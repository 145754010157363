import { ColumnType } from 'dash-table/components/Table/props';
import { getFormatter as getNumberFormatter } from './number';
const DEFAULT_FORMATTER = (value) => value;
export default (c) => {
    let formatter;
    switch (c.type) {
        case ColumnType.Numeric:
            formatter = getNumberFormatter(c.format);
            break;
    }
    return formatter || DEFAULT_FORMATTER;
};
