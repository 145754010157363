import valueCache from 'core/cache/value';
import { handleChange, handleClick, handleDoubleClick, handleEnter, handleLeave, handleMove, handleOnMouseUp, handlePaste } from 'dash-table/handlers/cellEvents';
export var Handler;
(function (Handler) {
    Handler["Change"] = "change";
    Handler["Click"] = "click";
    Handler["DoubleClick"] = "doubleclick";
    Handler["Enter"] = "enter";
    Handler["Leave"] = "leave";
    Handler["Move"] = "move";
    Handler["MouseUp"] = "mouseup";
    Handler["Paste"] = "paste";
})(Handler || (Handler = {}));
export default (propsFn) => new EventHandler(propsFn).get;
class EventHandler {
    constructor(propsFn) {
        this.propsFn = propsFn;
        this.cache = valueCache()((handler, rowIndex, columnIndex) => {
            switch (handler) {
                case Handler.Change:
                    return handleChange.bind(undefined, this.propsFn, rowIndex, columnIndex);
                case Handler.Click:
                    return handleClick.bind(undefined, this.propsFn, rowIndex, columnIndex);
                case Handler.DoubleClick:
                    return handleDoubleClick.bind(undefined, this.propsFn, rowIndex, columnIndex);
                case Handler.Enter:
                    return handleEnter.bind(undefined, this.propsFn, rowIndex, columnIndex);
                case Handler.Leave:
                    return handleLeave.bind(undefined, this.propsFn, rowIndex, columnIndex);
                case Handler.Move:
                    return handleMove.bind(undefined, this.propsFn, rowIndex, columnIndex);
                case Handler.MouseUp:
                    return handleOnMouseUp.bind(undefined, this.propsFn, rowIndex, columnIndex);
                case Handler.Paste:
                    return handlePaste.bind(undefined, this.propsFn, rowIndex, columnIndex);
                default:
                    throw new Error(`unexpected handler ${handler}`);
            }
        });
        this.get = (handler, rowIndex, columnIndex) => {
            return this.cache.get(handler, rowIndex, columnIndex);
        };
    }
}
