import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
function getter(columns, columnStyles, data, offset) {
    return R.addIndex(R.map)((datum, index) => R.map(column => {
        const relevantStyles = R.map(s => s.style, R.filter(style => style.matchesColumn(column) &&
            style.matchesRow(index + offset.rows) &&
            style.matchesFilter(datum), columnStyles));
        return relevantStyles.length ? R.mergeAll(relevantStyles) : undefined;
    }, columns), data);
}
export default memoizeOneFactory(getter);
