import { memoizeOne } from 'core/memoizer';
import { getCache } from '.';
export default () => {
    return (fn) => {
        const cache = new Map();
        function get(...key) {
            const lastKey = key.slice(-1)[0];
            const nestedCache = getCache(cache, ...key);
            return (nestedCache.get(lastKey) ||
                nestedCache.set(lastKey, memoizeOne(fn)).get(lastKey));
        }
        return { get };
    };
};
