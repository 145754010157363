export default new Map([
    ['align_content', 'alignContent'],
    ['align_items', 'alignItems'],
    ['alignment_adjust', 'alignmentAdjust'],
    ['alignment_baseline', 'alignmentBaseline'],
    ['align_self', 'alignSelf'],
    ['animation_delay', 'animationDelay'],
    ['animation_direction', 'animationDirection'],
    ['animation_iteration_count', 'animationIterationCount'],
    ['animation_name', 'animationName'],
    ['animation_play_state', 'animationPlayState'],
    ['appearance', 'appearance'],
    ['backface_visibility', 'backfaceVisibility'],
    ['background', 'background'],
    ['background_attachment', 'backgroundAttachment'],
    ['background_blend_mode', 'backgroundBlendMode'],
    ['background_color', 'backgroundColor'],
    ['background_composite', 'backgroundComposite'],
    ['background_image', 'backgroundImage'],
    ['background_origin', 'backgroundOrigin'],
    ['background_position', 'backgroundPosition'],
    ['background_repeat', 'backgroundRepeat'],
    ['baseline_shift', 'baselineShift'],
    ['behavior', 'behavior'],
    ['border', 'border'],
    ['border_bottom', 'borderBottom'],
    ['border_bottom_color', 'borderBottomColor'],
    ['border_bottom_left_radius', 'borderBottomLeftRadius'],
    ['border_bottom_right_radius', 'borderBottomRightRadius'],
    ['border_bottom_style', 'borderBottomStyle'],
    ['border_bottom_width', 'borderBottomWidth'],
    ['border_collapse', 'borderCollapse'],
    ['border_color', 'borderColor'],
    ['border_corner_shape', 'borderCornerShape'],
    ['border_image_source', 'borderImageSource'],
    ['border_image_width', 'borderImageWidth'],
    ['border_left', 'borderLeft'],
    ['border_left_color', 'borderLeftColor'],
    ['border_left_style', 'borderLeftStyle'],
    ['border_left_width', 'borderLeftWidth'],
    ['border_right', 'borderRight'],
    ['border_right_color', 'borderRightColor'],
    ['border_right_style', 'borderRightStyle'],
    ['border_right_width', 'borderRightWidth'],
    ['border_spacing', 'borderSpacing'],
    ['border_style', 'borderStyle'],
    ['border_top', 'borderTop'],
    ['border_top_color', 'borderTopColor'],
    ['border_top_left_radius', 'borderTopLeftRadius'],
    ['border_top_right_radius', 'borderTopRightRadius'],
    ['border_top_style', 'borderTopStyle'],
    ['border_top_width', 'borderTopWidth'],
    ['border_width', 'borderWidth'],
    ['bottom', 'bottom'],
    ['box_align', 'boxAlign'],
    ['box_decoration_break', 'boxDecorationBreak'],
    ['box_direction', 'boxDirection'],
    ['box_flex', 'boxFlex'],
    ['box_flex_group', 'boxFlexGroup'],
    ['box_line_progression', 'boxLineProgression'],
    ['box_lines', 'boxLines'],
    ['box_ordinal_group', 'boxOrdinalGroup'],
    ['box_shadow', 'boxShadow'],
    ['break_after', 'breakAfter'],
    ['break_before', 'breakBefore'],
    ['break_inside', 'breakInside'],
    ['clear', 'clear'],
    ['clip', 'clip'],
    ['clip_rule', 'clipRule'],
    ['color', 'color'],
    ['column_count', 'columnCount'],
    ['column_fill', 'columnFill'],
    ['column_gap', 'columnGap'],
    ['column_rule', 'columnRule'],
    ['column_rule_color', 'columnRuleColor'],
    ['column_rule_width', 'columnRuleWidth'],
    ['columns', 'columns'],
    ['column_span', 'columnSpan'],
    ['column_width', 'columnWidth'],
    ['counter_increment', 'counterIncrement'],
    ['counter_reset', 'counterReset'],
    ['cue', 'cue'],
    ['cue_after', 'cueAfter'],
    ['cursor', 'cursor'],
    ['direction', 'direction'],
    ['display', 'display'],
    ['fill', 'fill'],
    ['fill_opacity', 'fillOpacity'],
    ['fill_rule', 'fillRule'],
    ['filter', 'filter'],
    ['flex', 'flex'],
    ['flex_align', 'flexAlign'],
    ['flex_basis', 'flexBasis'],
    ['flex_direction', 'flexDirection'],
    ['flex_flow', 'flexFlow'],
    ['flex_grow', 'flexGrow'],
    ['flex_item_align', 'flexItemAlign'],
    ['flex_line_pack', 'flexLinePack'],
    ['flex_order', 'flexOrder'],
    ['flex_shrink', 'flexShrink'],
    ['flex_wrap', 'flexWrap'],
    ['float', 'float'],
    ['flow_from', 'flowFrom'],
    ['font', 'font'],
    ['font_family', 'fontFamily'],
    ['font_kerning', 'fontKerning'],
    ['font_size', 'fontSize'],
    ['font_size_adjust', 'fontSizeAdjust'],
    ['font_stretch', 'fontStretch'],
    ['font_style', 'fontStyle'],
    ['font_synthesis', 'fontSynthesis'],
    ['font_variant', 'fontVariant'],
    ['font_variant_alternates', 'fontVariantAlternates'],
    ['font_weight', 'fontWeight'],
    ['grid_area', 'gridArea'],
    ['grid_column', 'gridColumn'],
    ['grid_column_end', 'gridColumnEnd'],
    ['grid_column_start', 'gridColumnStart'],
    ['grid_row', 'gridRow'],
    ['grid_row_end', 'gridRowEnd'],
    ['grid_row_position', 'gridRowPosition'],
    ['grid_row_span', 'gridRowSpan'],
    ['grid_template_areas', 'gridTemplateAreas'],
    ['grid_template_columns', 'gridTemplateColumns'],
    ['grid_template_rows', 'gridTemplateRows'],
    ['height', 'height'],
    ['hyphenate_limit_chars', 'hyphenateLimitChars'],
    ['hyphenate_limit_lines', 'hyphenateLimitLines'],
    ['hyphenate_limit_zone', 'hyphenateLimitZone'],
    ['hyphens', 'hyphens'],
    ['ime_mode', 'imeMode'],
    ['justify_content', 'justifyContent'],
    ['layout_grid', 'layoutGrid'],
    ['layout_grid_char', 'layoutGridChar'],
    ['layout_grid_line', 'layoutGridLine'],
    ['layout_grid_mode', 'layoutGridMode'],
    ['layout_grid_type', 'layoutGridType'],
    ['left', 'left'],
    ['letter_spacing', 'letterSpacing'],
    ['line_break', 'lineBreak'],
    ['line_clamp', 'lineClamp'],
    ['line_height', 'lineHeight'],
    ['list_style', 'listStyle'],
    ['list_style_image', 'listStyleImage'],
    ['list_style_position', 'listStylePosition'],
    ['list_style_type', 'listStyleType'],
    ['margin', 'margin'],
    ['margin_bottom', 'marginBottom'],
    ['margin_left', 'marginLeft'],
    ['margin_right', 'marginRight'],
    ['margin_top', 'marginTop'],
    ['marquee_direction', 'marqueeDirection'],
    ['marquee_style', 'marqueeStyle'],
    ['mask', 'mask'],
    ['mask_border', 'maskBorder'],
    ['mask_border_repeat', 'maskBorderRepeat'],
    ['mask_border_slice', 'maskBorderSlice'],
    ['mask_border_source', 'maskBorderSource'],
    ['mask_border_width', 'maskBorderWidth'],
    ['mask_clip', 'maskClip'],
    ['mask_origin', 'maskOrigin'],
    ['max_font_size', 'maxFontSize'],
    ['max_height', 'maxHeight'],
    ['max_width', 'maxWidth'],
    ['min_height', 'minHeight'],
    ['min_width', 'minWidth'],
    ['opacity', 'opacity'],
    ['order', 'order'],
    ['orphans', 'orphans'],
    ['outline', 'outline'],
    ['outline_color', 'outlineColor'],
    ['outline_offset', 'outlineOffset'],
    ['overflow', 'overflow'],
    ['overflow_style', 'overflowStyle'],
    ['overflow_x', 'overflowX'],
    ['overflow_y', 'overflowY'],
    ['padding', 'padding'],
    ['padding_bottom', 'paddingBottom'],
    ['padding_left', 'paddingLeft'],
    ['padding_right', 'paddingRight'],
    ['padding_top', 'paddingTop'],
    ['page_break_after', 'pageBreakAfter'],
    ['page_break_before', 'pageBreakBefore'],
    ['page_break_inside', 'pageBreakInside'],
    ['pause', 'pause'],
    ['pause_after', 'pauseAfter'],
    ['pause_before', 'pauseBefore'],
    ['perspective', 'perspective'],
    ['perspective_origin', 'perspectiveOrigin'],
    ['pointer_events', 'pointerEvents'],
    ['position', 'position'],
    ['punctuation_trim', 'punctuationTrim'],
    ['quotes', 'quotes'],
    ['region_fragment', 'regionFragment'],
    ['rest_after', 'restAfter'],
    ['rest_before', 'restBefore'],
    ['right', 'right'],
    ['ruby_align', 'rubyAlign'],
    ['ruby_position', 'rubyPosition'],
    ['shape_image_threshold', 'shapeImageThreshold'],
    ['shape_inside', 'shapeInside'],
    ['shape_margin', 'shapeMargin'],
    ['shape_outside', 'shapeOutside'],
    ['speak', 'speak'],
    ['speak_as', 'speakAs'],
    ['stroke_opacity', 'strokeOpacity'],
    ['stroke_width', 'strokeWidth'],
    ['table_layout', 'tableLayout'],
    ['tab_size', 'tabSize'],
    ['text_align', 'textAlign'],
    ['text_align_last', 'textAlignLast'],
    ['text_decoration', 'textDecoration'],
    ['text_decoration_color', 'textDecorationColor'],
    ['text_decoration_line', 'textDecorationLine'],
    ['text_decoration_line_through', 'textDecorationLineThrough'],
    ['text_decoration_none', 'textDecorationNone'],
    ['text_decoration_overline', 'textDecorationOverline'],
    ['text_decoration_skip', 'textDecorationSkip'],
    ['text_decoration_style', 'textDecorationStyle'],
    ['text_decoration_underline', 'textDecorationUnderline'],
    ['text_emphasis', 'textEmphasis'],
    ['text_emphasis_color', 'textEmphasisColor'],
    ['text_emphasis_style', 'textEmphasisStyle'],
    ['text_height', 'textHeight'],
    ['text_indent', 'textIndent'],
    ['text_justify_trim', 'textJustifyTrim'],
    ['text_kashida_space', 'textKashidaSpace'],
    ['text_line_through', 'textLineThrough'],
    ['text_line_through_color', 'textLineThroughColor'],
    ['text_line_through_mode', 'textLineThroughMode'],
    ['text_line_through_style', 'textLineThroughStyle'],
    ['text_line_through_width', 'textLineThroughWidth'],
    ['text_overflow', 'textOverflow'],
    ['text_overline', 'textOverline'],
    ['text_overline_color', 'textOverlineColor'],
    ['text_overline_mode', 'textOverlineMode'],
    ['text_overline_style', 'textOverlineStyle'],
    ['text_overline_width', 'textOverlineWidth'],
    ['text_rendering', 'textRendering'],
    ['text_script', 'textScript'],
    ['text_shadow', 'textShadow'],
    ['text_transform', 'textTransform'],
    ['text_underline_position', 'textUnderlinePosition'],
    ['text_underline_style', 'textUnderlineStyle'],
    ['top', 'top'],
    ['touch_action', 'touchAction'],
    ['transform', 'transform'],
    ['transform_origin', 'transformOrigin'],
    ['transform_origin_z', 'transformOriginZ'],
    ['transform_style', 'transformStyle'],
    ['transition', 'transition'],
    ['transition_delay', 'transitionDelay'],
    ['transition_duration', 'transitionDuration'],
    ['transition_property', 'transitionProperty'],
    ['transition_timing_function', 'transitionTimingFunction'],
    ['unicode_bidi', 'unicodeBidi'],
    ['unicode_range', 'unicodeRange'],
    ['user_focus', 'userFocus'],
    ['user_input', 'userInput'],
    ['vertical_align', 'verticalAlign'],
    ['visibility', 'visibility'],
    ['voice_balance', 'voiceBalance'],
    ['voice_duration', 'voiceDuration'],
    ['voice_family', 'voiceFamily'],
    ['voice_pitch', 'voicePitch'],
    ['voice_range', 'voiceRange'],
    ['voice_rate', 'voiceRate'],
    ['voice_stress', 'voiceStress'],
    ['voice_volume', 'voiceVolume'],
    ['white_space', 'whiteSpace'],
    ['white_space_treatment', 'whiteSpaceTreatment'],
    ['widows', 'widows'],
    ['width', 'width'],
    ['word_break', 'wordBreak'],
    ['word_spacing', 'wordSpacing'],
    ['word_wrap', 'wordWrap'],
    ['wrap_flow', 'wrapFlow'],
    ['wrap_margin', 'wrapMargin'],
    ['wrap_option', 'wrapOption'],
    ['writing_mode', 'writingMode'],
    ['z_index', 'zIndex'],
    ['zoom', 'zoom'],
    ['align-content', 'alignContent'],
    ['align-items', 'alignItems'],
    ['alignment-adjust', 'alignmentAdjust'],
    ['alignment-baseline', 'alignmentBaseline'],
    ['align-self', 'alignSelf'],
    ['animation-delay', 'animationDelay'],
    ['animation-direction', 'animationDirection'],
    ['animation-iteration-count', 'animationIterationCount'],
    ['animation-name', 'animationName'],
    ['animation-play-state', 'animationPlayState'],
    ['backface-visibility', 'backfaceVisibility'],
    ['background-attachment', 'backgroundAttachment'],
    ['background-blend-mode', 'backgroundBlendMode'],
    ['background-color', 'backgroundColor'],
    ['background-composite', 'backgroundComposite'],
    ['background-image', 'backgroundImage'],
    ['background-origin', 'backgroundOrigin'],
    ['background-position', 'backgroundPosition'],
    ['background-repeat', 'backgroundRepeat'],
    ['baseline-shift', 'baselineShift'],
    ['border-bottom', 'borderBottom'],
    ['border-bottom-color', 'borderBottomColor'],
    ['border-bottom-left-radius', 'borderBottomLeftRadius'],
    ['border-bottom-right-radius', 'borderBottomRightRadius'],
    ['border-bottom-style', 'borderBottomStyle'],
    ['border-bottom-width', 'borderBottomWidth'],
    ['border-collapse', 'borderCollapse'],
    ['border-color', 'borderColor'],
    ['border-corner-shape', 'borderCornerShape'],
    ['border-image-source', 'borderImageSource'],
    ['border-image-width', 'borderImageWidth'],
    ['border-left', 'borderLeft'],
    ['border-left-color', 'borderLeftColor'],
    ['border-left-style', 'borderLeftStyle'],
    ['border-left-width', 'borderLeftWidth'],
    ['border-right', 'borderRight'],
    ['border-right-color', 'borderRightColor'],
    ['border-right-style', 'borderRightStyle'],
    ['border-right-width', 'borderRightWidth'],
    ['border-spacing', 'borderSpacing'],
    ['border-style', 'borderStyle'],
    ['border-top', 'borderTop'],
    ['border-top-color', 'borderTopColor'],
    ['border-top-left-radius', 'borderTopLeftRadius'],
    ['border-top-right-radius', 'borderTopRightRadius'],
    ['border-top-style', 'borderTopStyle'],
    ['border-top-width', 'borderTopWidth'],
    ['border-width', 'borderWidth'],
    ['box-align', 'boxAlign'],
    ['box-decoration-break', 'boxDecorationBreak'],
    ['box-direction', 'boxDirection'],
    ['box-flex', 'boxFlex'],
    ['box-flex-group', 'boxFlexGroup'],
    ['box-line-progression', 'boxLineProgression'],
    ['box-lines', 'boxLines'],
    ['box-ordinal-group', 'boxOrdinalGroup'],
    ['box-shadow', 'boxShadow'],
    ['break-after', 'breakAfter'],
    ['break-before', 'breakBefore'],
    ['break-inside', 'breakInside'],
    ['clip-rule', 'clipRule'],
    ['column-count', 'columnCount'],
    ['column-fill', 'columnFill'],
    ['column-gap', 'columnGap'],
    ['column-rule', 'columnRule'],
    ['column-rule-color', 'columnRuleColor'],
    ['column-rule-width', 'columnRuleWidth'],
    ['column-span', 'columnSpan'],
    ['column-width', 'columnWidth'],
    ['counter-increment', 'counterIncrement'],
    ['counter-reset', 'counterReset'],
    ['cue-after', 'cueAfter'],
    ['fill-opacity', 'fillOpacity'],
    ['fill-rule', 'fillRule'],
    ['flex-align', 'flexAlign'],
    ['flex-basis', 'flexBasis'],
    ['flex-direction', 'flexDirection'],
    ['flex-flow', 'flexFlow'],
    ['flex-grow', 'flexGrow'],
    ['flex-item-align', 'flexItemAlign'],
    ['flex-line-pack', 'flexLinePack'],
    ['flex-order', 'flexOrder'],
    ['flex-shrink', 'flexShrink'],
    ['flex-wrap', 'flexWrap'],
    ['flow-from', 'flowFrom'],
    ['font-family', 'fontFamily'],
    ['font-kerning', 'fontKerning'],
    ['font-size', 'fontSize'],
    ['font-size-adjust', 'fontSizeAdjust'],
    ['font-stretch', 'fontStretch'],
    ['font-style', 'fontStyle'],
    ['font-synthesis', 'fontSynthesis'],
    ['font-variant', 'fontVariant'],
    ['font-variant-alternates', 'fontVariantAlternates'],
    ['font-weight', 'fontWeight'],
    ['grid-area', 'gridArea'],
    ['grid-column', 'gridColumn'],
    ['grid-column-end', 'gridColumnEnd'],
    ['grid-column-start', 'gridColumnStart'],
    ['grid-row', 'gridRow'],
    ['grid-row-end', 'gridRowEnd'],
    ['grid-row-position', 'gridRowPosition'],
    ['grid-row-span', 'gridRowSpan'],
    ['grid-template-areas', 'gridTemplateAreas'],
    ['grid-template-columns', 'gridTemplateColumns'],
    ['grid-template-rows', 'gridTemplateRows'],
    ['hyphenate-limit-chars', 'hyphenateLimitChars'],
    ['hyphenate-limit-lines', 'hyphenateLimitLines'],
    ['hyphenate-limit-zone', 'hyphenateLimitZone'],
    ['ime-mode', 'imeMode'],
    ['justify-content', 'justifyContent'],
    ['layout-grid', 'layoutGrid'],
    ['layout-grid-char', 'layoutGridChar'],
    ['layout-grid-line', 'layoutGridLine'],
    ['layout-grid-mode', 'layoutGridMode'],
    ['layout-grid-type', 'layoutGridType'],
    ['letter-spacing', 'letterSpacing'],
    ['line-break', 'lineBreak'],
    ['line-clamp', 'lineClamp'],
    ['line-height', 'lineHeight'],
    ['list-style', 'listStyle'],
    ['list-style-image', 'listStyleImage'],
    ['list-style-position', 'listStylePosition'],
    ['list-style-type', 'listStyleType'],
    ['margin-bottom', 'marginBottom'],
    ['margin-left', 'marginLeft'],
    ['margin-right', 'marginRight'],
    ['margin-top', 'marginTop'],
    ['marquee-direction', 'marqueeDirection'],
    ['marquee-style', 'marqueeStyle'],
    ['mask-border', 'maskBorder'],
    ['mask-border-repeat', 'maskBorderRepeat'],
    ['mask-border-slice', 'maskBorderSlice'],
    ['mask-border-source', 'maskBorderSource'],
    ['mask-border-width', 'maskBorderWidth'],
    ['mask-clip', 'maskClip'],
    ['mask-origin', 'maskOrigin'],
    ['max-font-size', 'maxFontSize'],
    ['max-height', 'maxHeight'],
    ['max-width', 'maxWidth'],
    ['min-height', 'minHeight'],
    ['min-width', 'minWidth'],
    ['outline-color', 'outlineColor'],
    ['outline-offset', 'outlineOffset'],
    ['overflow-style', 'overflowStyle'],
    ['overflow-x', 'overflowX'],
    ['overflow-y', 'overflowY'],
    ['padding-bottom', 'paddingBottom'],
    ['padding-left', 'paddingLeft'],
    ['padding-right', 'paddingRight'],
    ['padding-top', 'paddingTop'],
    ['page-break-after', 'pageBreakAfter'],
    ['page-break-before', 'pageBreakBefore'],
    ['page-break-inside', 'pageBreakInside'],
    ['pause-after', 'pauseAfter'],
    ['pause-before', 'pauseBefore'],
    ['perspective-origin', 'perspectiveOrigin'],
    ['pointer-events', 'pointerEvents'],
    ['punctuation-trim', 'punctuationTrim'],
    ['region-fragment', 'regionFragment'],
    ['rest-after', 'restAfter'],
    ['rest-before', 'restBefore'],
    ['ruby-align', 'rubyAlign'],
    ['ruby-position', 'rubyPosition'],
    ['shape-image-threshold', 'shapeImageThreshold'],
    ['shape-inside', 'shapeInside'],
    ['shape-margin', 'shapeMargin'],
    ['shape-outside', 'shapeOutside'],
    ['speak-as', 'speakAs'],
    ['stroke-opacity', 'strokeOpacity'],
    ['stroke-width', 'strokeWidth'],
    ['table-layout', 'tableLayout'],
    ['tab-size', 'tabSize'],
    ['text-align', 'textAlign'],
    ['text-align-last', 'textAlignLast'],
    ['text-decoration', 'textDecoration'],
    ['text-decoration-color', 'textDecorationColor'],
    ['text-decoration-line', 'textDecorationLine'],
    ['text-decoration-line-through', 'textDecorationLineThrough'],
    ['text-decoration-none', 'textDecorationNone'],
    ['text-decoration-overline', 'textDecorationOverline'],
    ['text-decoration-skip', 'textDecorationSkip'],
    ['text-decoration-style', 'textDecorationStyle'],
    ['text-decoration-underline', 'textDecorationUnderline'],
    ['text-emphasis', 'textEmphasis'],
    ['text-emphasis-color', 'textEmphasisColor'],
    ['text-emphasis-style', 'textEmphasisStyle'],
    ['text-height', 'textHeight'],
    ['text-indent', 'textIndent'],
    ['text-justify-trim', 'textJustifyTrim'],
    ['text-kashida-space', 'textKashidaSpace'],
    ['text-line-through', 'textLineThrough'],
    ['text-line-through-color', 'textLineThroughColor'],
    ['text-line-through-mode', 'textLineThroughMode'],
    ['text-line-through-style', 'textLineThroughStyle'],
    ['text-line-through-width', 'textLineThroughWidth'],
    ['text-overflow', 'textOverflow'],
    ['text-overline', 'textOverline'],
    ['text-overline-color', 'textOverlineColor'],
    ['text-overline-mode', 'textOverlineMode'],
    ['text-overline-style', 'textOverlineStyle'],
    ['text-overline-width', 'textOverlineWidth'],
    ['text-rendering', 'textRendering'],
    ['text-script', 'textScript'],
    ['text-shadow', 'textShadow'],
    ['text-transform', 'textTransform'],
    ['text-underline-position', 'textUnderlinePosition'],
    ['text-underline-style', 'textUnderlineStyle'],
    ['touch-action', 'touchAction'],
    ['transform-origin', 'transformOrigin'],
    ['transform-origin-z', 'transformOriginZ'],
    ['transform-style', 'transformStyle'],
    ['transition-delay', 'transitionDelay'],
    ['transition-duration', 'transitionDuration'],
    ['transition-property', 'transitionProperty'],
    ['transition-timing-function', 'transitionTimingFunction'],
    ['unicode-bidi', 'unicodeBidi'],
    ['unicode-range', 'unicodeRange'],
    ['user-focus', 'userFocus'],
    ['user-input', 'userInput'],
    ['vertical-align', 'verticalAlign'],
    ['voice-balance', 'voiceBalance'],
    ['voice-duration', 'voiceDuration'],
    ['voice-family', 'voiceFamily'],
    ['voice-pitch', 'voicePitch'],
    ['voice-range', 'voiceRange'],
    ['voice-rate', 'voiceRate'],
    ['voice-stress', 'voiceStress'],
    ['voice-volume', 'voiceVolume'],
    ['white-space', 'whiteSpace'],
    ['white-space-treatment', 'whiteSpaceTreatment'],
    ['word-break', 'wordBreak'],
    ['word-spacing', 'wordSpacing'],
    ['word-wrap', 'wordWrap'],
    ['wrap-flow', 'wrapFlow'],
    ['wrap-margin', 'wrapMargin'],
    ['wrap-option', 'wrapOption'],
    ['writing-mode', 'writingMode'],
    ['z-index', 'zIndex'],
    ['alignContent', 'alignContent'],
    ['alignItems', 'alignItems'],
    ['alignmentAdjust', 'alignmentAdjust'],
    ['alignmentBaseline', 'alignmentBaseline'],
    ['alignSelf', 'alignSelf'],
    ['animationDelay', 'animationDelay'],
    ['animationDirection', 'animationDirection'],
    ['animationIterationCount', 'animationIterationCount'],
    ['animationName', 'animationName'],
    ['animationPlayState', 'animationPlayState'],
    ['backfaceVisibility', 'backfaceVisibility'],
    ['backgroundAttachment', 'backgroundAttachment'],
    ['backgroundBlendMode', 'backgroundBlendMode'],
    ['backgroundColor', 'backgroundColor'],
    ['backgroundComposite', 'backgroundComposite'],
    ['backgroundImage', 'backgroundImage'],
    ['backgroundOrigin', 'backgroundOrigin'],
    ['backgroundPosition', 'backgroundPosition'],
    ['backgroundRepeat', 'backgroundRepeat'],
    ['baselineShift', 'baselineShift'],
    ['borderBottom', 'borderBottom'],
    ['borderBottomColor', 'borderBottomColor'],
    ['borderBottomLeftRadius', 'borderBottomLeftRadius'],
    ['borderBottomRightRadius', 'borderBottomRightRadius'],
    ['borderBottomStyle', 'borderBottomStyle'],
    ['borderBottomWidth', 'borderBottomWidth'],
    ['borderCollapse', 'borderCollapse'],
    ['borderColor', 'borderColor'],
    ['borderCornerShape', 'borderCornerShape'],
    ['borderImageSource', 'borderImageSource'],
    ['borderImageWidth', 'borderImageWidth'],
    ['borderLeft', 'borderLeft'],
    ['borderLeftColor', 'borderLeftColor'],
    ['borderLeftStyle', 'borderLeftStyle'],
    ['borderLeftWidth', 'borderLeftWidth'],
    ['borderRight', 'borderRight'],
    ['borderRightColor', 'borderRightColor'],
    ['borderRightStyle', 'borderRightStyle'],
    ['borderRightWidth', 'borderRightWidth'],
    ['borderSpacing', 'borderSpacing'],
    ['borderStyle', 'borderStyle'],
    ['borderTop', 'borderTop'],
    ['borderTopColor', 'borderTopColor'],
    ['borderTopLeftRadius', 'borderTopLeftRadius'],
    ['borderTopRightRadius', 'borderTopRightRadius'],
    ['borderTopStyle', 'borderTopStyle'],
    ['borderTopWidth', 'borderTopWidth'],
    ['borderWidth', 'borderWidth'],
    ['boxAlign', 'boxAlign'],
    ['boxDecorationBreak', 'boxDecorationBreak'],
    ['boxDirection', 'boxDirection'],
    ['boxFlex', 'boxFlex'],
    ['boxFlexGroup', 'boxFlexGroup'],
    ['boxLineProgression', 'boxLineProgression'],
    ['boxLines', 'boxLines'],
    ['boxOrdinalGroup', 'boxOrdinalGroup'],
    ['boxShadow', 'boxShadow'],
    ['breakAfter', 'breakAfter'],
    ['breakBefore', 'breakBefore'],
    ['breakInside', 'breakInside'],
    ['clipRule', 'clipRule'],
    ['columnCount', 'columnCount'],
    ['columnFill', 'columnFill'],
    ['columnGap', 'columnGap'],
    ['columnRule', 'columnRule'],
    ['columnRuleColor', 'columnRuleColor'],
    ['columnRuleWidth', 'columnRuleWidth'],
    ['columnSpan', 'columnSpan'],
    ['columnWidth', 'columnWidth'],
    ['counterIncrement', 'counterIncrement'],
    ['counterReset', 'counterReset'],
    ['cueAfter', 'cueAfter'],
    ['fillOpacity', 'fillOpacity'],
    ['fillRule', 'fillRule'],
    ['flexAlign', 'flexAlign'],
    ['flexBasis', 'flexBasis'],
    ['flexDirection', 'flexDirection'],
    ['flexFlow', 'flexFlow'],
    ['flexGrow', 'flexGrow'],
    ['flexItemAlign', 'flexItemAlign'],
    ['flexLinePack', 'flexLinePack'],
    ['flexOrder', 'flexOrder'],
    ['flexShrink', 'flexShrink'],
    ['flexWrap', 'flexWrap'],
    ['flowFrom', 'flowFrom'],
    ['fontFamily', 'fontFamily'],
    ['fontKerning', 'fontKerning'],
    ['fontSize', 'fontSize'],
    ['fontSizeAdjust', 'fontSizeAdjust'],
    ['fontStretch', 'fontStretch'],
    ['fontStyle', 'fontStyle'],
    ['fontSynthesis', 'fontSynthesis'],
    ['fontVariant', 'fontVariant'],
    ['fontVariantAlternates', 'fontVariantAlternates'],
    ['fontWeight', 'fontWeight'],
    ['gridArea', 'gridArea'],
    ['gridColumn', 'gridColumn'],
    ['gridColumnEnd', 'gridColumnEnd'],
    ['gridColumnStart', 'gridColumnStart'],
    ['gridRow', 'gridRow'],
    ['gridRowEnd', 'gridRowEnd'],
    ['gridRowPosition', 'gridRowPosition'],
    ['gridRowSpan', 'gridRowSpan'],
    ['gridTemplateAreas', 'gridTemplateAreas'],
    ['gridTemplateColumns', 'gridTemplateColumns'],
    ['gridTemplateRows', 'gridTemplateRows'],
    ['hyphenateLimitChars', 'hyphenateLimitChars'],
    ['hyphenateLimitLines', 'hyphenateLimitLines'],
    ['hyphenateLimitZone', 'hyphenateLimitZone'],
    ['imeMode', 'imeMode'],
    ['justifyContent', 'justifyContent'],
    ['layoutGrid', 'layoutGrid'],
    ['layoutGridChar', 'layoutGridChar'],
    ['layoutGridLine', 'layoutGridLine'],
    ['layoutGridMode', 'layoutGridMode'],
    ['layoutGridType', 'layoutGridType'],
    ['letterSpacing', 'letterSpacing'],
    ['lineBreak', 'lineBreak'],
    ['lineClamp', 'lineClamp'],
    ['lineHeight', 'lineHeight'],
    ['listStyle', 'listStyle'],
    ['listStyleImage', 'listStyleImage'],
    ['listStylePosition', 'listStylePosition'],
    ['listStyleType', 'listStyleType'],
    ['marginBottom', 'marginBottom'],
    ['marginLeft', 'marginLeft'],
    ['marginRight', 'marginRight'],
    ['marginTop', 'marginTop'],
    ['marqueeDirection', 'marqueeDirection'],
    ['marqueeStyle', 'marqueeStyle'],
    ['maskBorder', 'maskBorder'],
    ['maskBorderRepeat', 'maskBorderRepeat'],
    ['maskBorderSlice', 'maskBorderSlice'],
    ['maskBorderSource', 'maskBorderSource'],
    ['maskBorderWidth', 'maskBorderWidth'],
    ['maskClip', 'maskClip'],
    ['maskOrigin', 'maskOrigin'],
    ['maxFontSize', 'maxFontSize'],
    ['maxHeight', 'maxHeight'],
    ['maxWidth', 'maxWidth'],
    ['minHeight', 'minHeight'],
    ['minWidth', 'minWidth'],
    ['outlineColor', 'outlineColor'],
    ['outlineOffset', 'outlineOffset'],
    ['overflowStyle', 'overflowStyle'],
    ['overflowX', 'overflowX'],
    ['overflowY', 'overflowY'],
    ['paddingBottom', 'paddingBottom'],
    ['paddingLeft', 'paddingLeft'],
    ['paddingRight', 'paddingRight'],
    ['paddingTop', 'paddingTop'],
    ['pageBreakAfter', 'pageBreakAfter'],
    ['pageBreakBefore', 'pageBreakBefore'],
    ['pageBreakInside', 'pageBreakInside'],
    ['pauseAfter', 'pauseAfter'],
    ['pauseBefore', 'pauseBefore'],
    ['perspectiveOrigin', 'perspectiveOrigin'],
    ['pointerEvents', 'pointerEvents'],
    ['punctuationTrim', 'punctuationTrim'],
    ['regionFragment', 'regionFragment'],
    ['restAfter', 'restAfter'],
    ['restBefore', 'restBefore'],
    ['rubyAlign', 'rubyAlign'],
    ['rubyPosition', 'rubyPosition'],
    ['shapeImageThreshold', 'shapeImageThreshold'],
    ['shapeInside', 'shapeInside'],
    ['shapeMargin', 'shapeMargin'],
    ['shapeOutside', 'shapeOutside'],
    ['speakAs', 'speakAs'],
    ['strokeOpacity', 'strokeOpacity'],
    ['strokeWidth', 'strokeWidth'],
    ['tableLayout', 'tableLayout'],
    ['tabSize', 'tabSize'],
    ['textAlign', 'textAlign'],
    ['textAlignLast', 'textAlignLast'],
    ['textDecoration', 'textDecoration'],
    ['textDecorationColor', 'textDecorationColor'],
    ['textDecorationLine', 'textDecorationLine'],
    ['textDecorationLineThrough', 'textDecorationLineThrough'],
    ['textDecorationNone', 'textDecorationNone'],
    ['textDecorationOverline', 'textDecorationOverline'],
    ['textDecorationSkip', 'textDecorationSkip'],
    ['textDecorationStyle', 'textDecorationStyle'],
    ['textDecorationUnderline', 'textDecorationUnderline'],
    ['textEmphasis', 'textEmphasis'],
    ['textEmphasisColor', 'textEmphasisColor'],
    ['textEmphasisStyle', 'textEmphasisStyle'],
    ['textHeight', 'textHeight'],
    ['textIndent', 'textIndent'],
    ['textJustifyTrim', 'textJustifyTrim'],
    ['textKashidaSpace', 'textKashidaSpace'],
    ['textLineThrough', 'textLineThrough'],
    ['textLineThroughColor', 'textLineThroughColor'],
    ['textLineThroughMode', 'textLineThroughMode'],
    ['textLineThroughStyle', 'textLineThroughStyle'],
    ['textLineThroughWidth', 'textLineThroughWidth'],
    ['textOverflow', 'textOverflow'],
    ['textOverline', 'textOverline'],
    ['textOverlineColor', 'textOverlineColor'],
    ['textOverlineMode', 'textOverlineMode'],
    ['textOverlineStyle', 'textOverlineStyle'],
    ['textOverlineWidth', 'textOverlineWidth'],
    ['textRendering', 'textRendering'],
    ['textScript', 'textScript'],
    ['textShadow', 'textShadow'],
    ['textTransform', 'textTransform'],
    ['textUnderlinePosition', 'textUnderlinePosition'],
    ['textUnderlineStyle', 'textUnderlineStyle'],
    ['touchAction', 'touchAction'],
    ['transformOrigin', 'transformOrigin'],
    ['transformOriginZ', 'transformOriginZ'],
    ['transformStyle', 'transformStyle'],
    ['transitionDelay', 'transitionDelay'],
    ['transitionDuration', 'transitionDuration'],
    ['transitionProperty', 'transitionProperty'],
    ['transitionTimingFunction', 'transitionTimingFunction'],
    ['unicodeBidi', 'unicodeBidi'],
    ['unicodeRange', 'unicodeRange'],
    ['userFocus', 'userFocus'],
    ['userInput', 'userInput'],
    ['verticalAlign', 'verticalAlign'],
    ['voiceBalance', 'voiceBalance'],
    ['voiceDuration', 'voiceDuration'],
    ['voiceFamily', 'voiceFamily'],
    ['voicePitch', 'voicePitch'],
    ['voiceRange', 'voiceRange'],
    ['voiceRate', 'voiceRate'],
    ['voiceStress', 'voiceStress'],
    ['voiceVolume', 'voiceVolume'],
    ['whiteSpace', 'whiteSpace'],
    ['whiteSpaceTreatment', 'whiteSpaceTreatment'],
    ['wordBreak', 'wordBreak'],
    ['wordSpacing', 'wordSpacing'],
    ['wordWrap', 'wordWrap'],
    ['wrapFlow', 'wrapFlow'],
    ['wrapMargin', 'wrapMargin'],
    ['wrapOption', 'wrapOption'],
    ['writingMode', 'writingMode'],
    ['zIndex', 'zIndex']
]);
