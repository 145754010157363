export default class Clipboard {
    static set(_ev, value) {
        _ev.clipboardData.setData('text/plain', value);
        _ev.preventDefault();
    }
    static get(_ev) {
        let value;
        value = _ev.clipboardData ?
            _ev.clipboardData.getData('text/plain') :
            undefined;
        return value;
    }
}
