import React, { PureComponent } from 'react';
import { KEY_CODES } from 'dash-table/utils/unicode';
export default class IsolatedInput extends PureComponent {
    constructor(props) {
        super(props);
        this.handleKeyDown = (e) => {
            const { stopPropagation, updateOnEnter } = this.propsWithDefaults;
            if (stopPropagation) {
                e.stopPropagation();
            }
            if (updateOnEnter && e.keyCode === KEY_CODES.ENTER) {
                this.submit();
            }
        };
        this.handleChange = (ev) => {
            this.setState({
                value: ev.target.value
            });
        };
        this.submit = () => this.state.value !== this.props.value &&
            this.props.submit(this.state.value);
        this.state = {
            value: props.value
        };
    }
    get propsWithDefaults() {
        return this.props;
    }
    componentWillReceiveProps(nextProps) {
        const { value: nextValue } = nextProps;
        if (this.state.value !== nextValue) {
            this.setState({
                value: nextValue
            });
        }
    }
    render() {
        const { placeholder, updateOnBlur, updateOnSubmit } = this.propsWithDefaults;
        let props = {
            onBlur: updateOnBlur ? this.submit : undefined,
            onKeyDown: this.handleKeyDown,
            onSubmit: updateOnSubmit ? this.submit : undefined
        };
        return (React.createElement("input", Object.assign({ ref: 'input', type: 'text', value: this.state.value || '', onChange: this.handleChange, placeholder: placeholder }, props)));
    }
}
IsolatedInput.defaultProps = {
    stopPropagation: false,
    updateOnEnter: true,
    updateOnBlur: true,
    updateOnSubmit: true
};
