import * as R from 'ramda';
import React from 'react';
import { Presentation } from 'dash-table/components/Table/props';
import CellInput from 'dash-table/components/CellInput';
import derivedCellEventHandlerProps, { Handler } from 'dash-table/derived/cell/eventHandlerProps';
import isActiveCell from 'dash-table/derived/cell/isActive';
import isCellEditable from './isEditable';
import CellLabel from 'dash-table/components/CellLabel';
import CellDropdown from 'dash-table/components/CellDropdown';
import { memoizeOne } from 'core/memoizer';
import getFormatter from 'dash-table/type/formatter';
const mapData = R.addIndex(R.map);
const mapRow = R.addIndex(R.map);
var CellType;
(function (CellType) {
    CellType[CellType["Dropdown"] = 0] = "Dropdown";
    CellType[CellType["Input"] = 1] = "Input";
    CellType[CellType["Label"] = 2] = "Label";
})(CellType || (CellType = {}));
function getCellType(active, editable, dropdown, presentation) {
    switch (presentation) {
        case Presentation.Input:
            return (!active || !editable) ? CellType.Label : CellType.Input;
        case Presentation.Dropdown:
            return (!dropdown || !editable) ? CellType.Label : CellType.Dropdown;
        default:
            return (!active || !editable) ? CellType.Label : CellType.Input;
    }
}
export default (propsFn) => new Contents(propsFn).get;
class Contents {
    constructor(propsFn, handlers = derivedCellEventHandlerProps(propsFn)) {
        this.handlers = handlers;
        this.get = memoizeOne((activeCell, columns, data, offset, editable, isFocused, dropdowns) => {
            const formatters = R.map(getFormatter, columns);
            return mapData((datum, rowIndex) => mapRow((column, columnIndex) => {
                const active = isActiveCell(activeCell, rowIndex + offset.rows, columnIndex + offset.columns);
                const dropdown = dropdowns[rowIndex][columnIndex];
                const isEditable = isCellEditable(editable, column.editable);
                const className = [
                    ...(active ? ['input-active'] : []),
                    isFocused ? 'focused' : 'unfocused',
                    'dash-cell-value'
                ].join(' ');
                switch (getCellType(active, isEditable, dropdown, column.presentation)) {
                    case CellType.Dropdown:
                        return (React.createElement(CellDropdown, { key: `column-${columnIndex}`, active: active, clearable: column.clearable, dropdown: dropdown, onChange: this.handlers(Handler.Change, rowIndex, columnIndex), value: datum[column.id] }));
                    case CellType.Input:
                        return (React.createElement(CellInput, { key: `column-${columnIndex}`, active: active, className: className, focused: isFocused, onChange: this.handlers(Handler.Change, rowIndex, columnIndex), onClick: this.handlers(Handler.Click, rowIndex, columnIndex), onDoubleClick: this.handlers(Handler.DoubleClick, rowIndex, columnIndex), onMouseUp: this.handlers(Handler.MouseUp, rowIndex, columnIndex), onPaste: this.handlers(Handler.Paste, rowIndex, columnIndex), type: column.type, value: datum[column.id] }));
                    case CellType.Label:
                    default:
                        return (React.createElement(CellLabel, { className: className, key: `column-${columnIndex}`, onClick: this.handlers(Handler.Click, rowIndex, columnIndex), onDoubleClick: this.handlers(Handler.DoubleClick, rowIndex, columnIndex), value: formatters[columnIndex](datum[column.id]) }));
                }
            }, columns), data);
        });
    }
}
