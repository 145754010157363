import Logger from 'core/Logger';
import lexer from 'core/syntax-tree/lexer';
import syntaxer from 'core/syntax-tree/syntaxer';
export default class SyntaxTree {
    constructor(query) {
        this.query = query;
        this.evaluate = (target) => {
            if (!this.isValid) {
                const msg = `unable to evaluate target: syntax tree is invalid for query=${this.query}`;
                Logger.error(msg);
                throw new Error(msg);
            }
            return this.tree && this.tree.lexeme && this.tree.lexeme.evaluate ?
                this.tree.lexeme.evaluate(target, this.tree) :
                true;
        };
        this.filter = (targets) => {
            return targets.filter(this.evaluate);
        };
        this.result = syntaxer(lexer(this.query));
    }
    get isValid() {
        return this.result.valid;
    }
    get tree() {
        return this.result.tree;
    }
}
