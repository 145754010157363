import * as R from 'ramda';
import isActive from 'dash-table/derived/cell/isActive';
import reconcile from 'dash-table/type/reconcile';
function isCellSelected(selectedCells, idx, i) {
    return selectedCells && R.contains([idx, i], selectedCells);
}
export const handleClick = (propsFn, idx, i, e) => {
    const { selected_cells, setProps, virtualized } = propsFn();
    const selected = isCellSelected(selected_cells, idx, i);
    // don't update if already selected
    if (selected) {
        return;
    }
    e.preventDefault();
    const cellLocation = [
        idx + virtualized.offset.rows,
        i + virtualized.offset.columns
    ];
    const newProps = {
        is_focused: false,
        active_cell: cellLocation
    };
    const selectedRows = R.uniq(R.pluck(0, selected_cells)).sort((a, b) => a - b);
    const selectedCols = R.uniq(R.pluck(1, selected_cells)).sort((a, b) => a - b);
    const minRow = selectedRows[0];
    const minCol = selectedCols[0];
    if (e.shiftKey) {
        newProps.selected_cells = R.xprod(R.range(R.min(minRow, cellLocation[0]), R.max(minRow, cellLocation[0]) + 1), R.range(R.min(minCol, cellLocation[1]), R.max(minCol, cellLocation[1]) + 1));
    }
    else {
        newProps.selected_cells = [cellLocation];
    }
    setProps(newProps);
};
export const handleDoubleClick = (propsFn, idx, i, e) => {
    const { editable, is_focused, setProps, virtualized } = propsFn();
    if (!editable) {
        return;
    }
    const cellLocation = [
        idx + virtualized.offset.rows,
        i + virtualized.offset.columns
    ];
    if (!is_focused) {
        e.preventDefault();
        const newProps = {
            selected_cells: [cellLocation],
            active_cell: cellLocation,
            is_focused: true
        };
        setProps(newProps);
    }
};
export const handleChange = (propsFn, idx, i, value) => {
    const { columns, data, editable, setProps, virtualized } = propsFn();
    const c = columns[i];
    const realIdx = virtualized.indices[idx];
    if (!editable) {
        return;
    }
    const result = reconcile(value, c);
    if (!result.success) {
        return;
    }
    const newData = R.set(R.lensPath([realIdx, c.id]), result.value, data);
    setProps({
        data: newData
    });
};
export const handleEnter = (propsFn, idx, i) => {
    const { columns, virtualized, setState } = propsFn();
    const c = columns[i];
    const realIdx = virtualized.indices[idx];
    setState({
        tooltip: {
            id: c.id,
            row: realIdx
        }
    });
};
export const handleLeave = (propsFn, _idx, _i) => {
    const { setState } = propsFn();
    setState({ tooltip: undefined });
};
export const handleMove = (propsFn, idx, i) => {
    const { columns, virtualized, setState, tooltip } = propsFn();
    const c = columns[i];
    const realIdx = virtualized.indices[idx];
    if (tooltip && tooltip.id === c.id && tooltip.row === realIdx) {
        return;
    }
    setState({
        tooltip: {
            id: c.id,
            row: realIdx
        }
    });
};
export const handleOnMouseUp = (propsFn, idx, i, e) => {
    const { active_cell, is_focused } = propsFn();
    const active = isActive(active_cell, idx, i);
    if (!is_focused && active) {
        e.preventDefault();
        // We do this because mouseMove can change the selection, we don't want
        // to check for all mouse movements, for performance reasons.
        const input = e.target;
        input.setSelectionRange(0, input.value ? input.value.length : 0);
    }
};
export const handlePaste = (_propsFn, _idx, _i, e) => {
    e.preventDefault();
};
