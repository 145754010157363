import * as R from 'ramda';
export var SortDirection;
(function (SortDirection) {
    SortDirection["Ascending"] = "asc";
    SortDirection["Descending"] = "desc";
    SortDirection["None"] = "none";
})(SortDirection || (SortDirection = {}));
export const defaultIsNully = (value) => value === undefined || value === null;
export default (data, settings, isNully = defaultIsNully) => {
    if (!settings.length) {
        return data;
    }
    return R.sortWith(R.map(setting => {
        return setting.direction === SortDirection.Descending ?
            R.comparator((d1, d2) => {
                const id = setting.column_id;
                const prop1 = d1[id];
                const prop2 = d2[id];
                if (isNully(prop1)) {
                    return false;
                }
                else if (isNully(prop2)) {
                    return true;
                }
                return prop1 > prop2;
            }) :
            R.comparator((d1, d2) => {
                const id = setting.column_id;
                const prop1 = d1[id];
                const prop2 = d2[id];
                if (isNully(prop1)) {
                    return false;
                }
                else if (isNully(prop2)) {
                    return true;
                }
                return prop1 < prop2;
            });
    }, settings), data);
};
