import * as R from 'ramda';
import Logger from 'core/Logger';
import { SortDirection } from 'core/sorting';
export default (settings, setting) => {
    Logger.trace('multi - updateSettings', settings, setting);
    settings = R.clone(settings);
    if (setting.direction === SortDirection.None) {
        const currentIndex = R.findIndex(s => s.column_id === setting.column_id, settings);
        if (currentIndex !== -1) {
            settings.splice(currentIndex, 1);
        }
    }
    else {
        const currentSetting = R.find(s => s.column_id === setting.column_id, settings);
        if (currentSetting) {
            currentSetting.direction = setting.direction;
        }
        else {
            settings.push(setting);
        }
    }
    return settings;
};
