import * as R from 'ramda';
import SheetClip from 'sheetclip';
import Clipboard from 'core/Clipboard';
import Logger from 'core/Logger';
import applyClipboardToData from './applyClipboardToData';
export default class TableClipboardHelper {
    static toClipboard(e, selectedCells, columns, data) {
        const selectedRows = R.uniq(R.pluck(0, selectedCells).sort((a, b) => a - b));
        const selectedCols = R.uniq(R.pluck(1, selectedCells).sort((a, b) => a - b));
        const df = R.slice(R.head(selectedRows), R.last(selectedRows) + 1, data).map(row => R.props(selectedCols, R.props(R.pluck('id', columns), row)));
        const value = SheetClip.prototype.stringify(df);
        TableClipboardHelper.lastLocalCopy = df;
        Logger.trace('TableClipboard -- set clipboard data: ', value);
        Clipboard.set(e, value);
    }
    static fromClipboard(ev, activeCell, derived_viewport_indices, columns, data, overflowColumns = true, overflowRows = true) {
        const text = Clipboard.get(ev);
        Logger.trace('TableClipboard -- get clipboard data: ', text);
        if (!text) {
            return;
        }
        const localDf = SheetClip.prototype.stringify(TableClipboardHelper.lastLocalCopy);
        const values = localDf === text ?
            TableClipboardHelper.lastLocalCopy :
            SheetClip.prototype.parse(text);
        return applyClipboardToData(values, activeCell, derived_viewport_indices, columns, data, overflowColumns, overflowRows);
    }
}
TableClipboardHelper.lastLocalCopy = [[]];
