import { ColumnType } from 'dash-table/components/Table/props';
import SyntaxTree from 'core/syntax-tree';
function ifAstFilter(ast, datum) {
    return ast.isValid && ast.evaluate(datum);
}
export function ifColumnId(condition, columnId) {
    return !condition ||
        condition.column_id === undefined ||
        condition.column_id === columnId;
}
export function ifColumnType(condition, columnType) {
    return !condition ||
        condition.column_type === undefined ||
        condition.column_type === (columnType || ColumnType.Any);
}
export function ifRowIndex(condition, rowIndex) {
    if (!condition ||
        condition.row_index === undefined) {
        return true;
    }
    const rowCondition = condition.row_index;
    return typeof rowCondition === 'number' ?
        rowIndex === rowCondition :
        rowCondition === 'odd' ? rowIndex % 2 === 1 : rowIndex % 2 === 0;
}
export function ifHeaderIndex(condition, headerIndex) {
    if (!condition ||
        condition.header_index === undefined) {
        return true;
    }
    const headerCondition = condition.header_index;
    return typeof headerCondition === 'number' ?
        headerIndex === headerCondition :
        headerCondition === 'odd' ? headerIndex % 2 === 1 : headerIndex % 2 === 0;
}
export function ifFilter(condition, datum) {
    return !condition ||
        condition.filter === undefined ||
        ifAstFilter(new SyntaxTree(condition.filter), datum);
}
