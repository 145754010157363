import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
const getter = (indices, selectedRows) => {
    const map = new Map();
    R.addIndex(R.forEach)((virtualIndex, index) => {
        map.set(virtualIndex, index);
    }, indices);
    const mappedSelectedRows = [];
    R.forEach(rowIndex => {
        const virtualIndex = map.get(rowIndex);
        if (virtualIndex !== undefined) {
            mappedSelectedRows.push(virtualIndex);
        }
    }, selectedRows);
    return mappedSelectedRows;
};
export default memoizeOneFactory(getter);
