import * as R from 'ramda';
export function matrixMap(m1, m2, cb) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(ijValue, m2[i][j], i, j), iRow), m1);
}
export function matrixMap3(m1, m2, m3, cb) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(ijValue, m2[i][j], m3[i][j], i, j), iRow), m1);
}
export function matrixMap4(m1, m2, m3, m4, cb) {
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(ijValue, m2[i][j], m3[i][j], m4[i][j], i, j), iRow), m1);
}
export function matrixMapN(cb, ...matrices) {
    const m1 = matrices.slice(0, 1);
    const ms = matrices.slice(1);
    const mapMatrix = R.addIndex(R.map);
    const mapRow = R.addIndex(R.map);
    return mapMatrix((iRow, i) => mapRow((ijValue, j) => cb(i, j, [ijValue, ...ms.map(m => m[i][j])]), iRow), m1);
}
