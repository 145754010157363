import * as R from 'ramda';
import React from 'react';
import { memoizeOneFactory } from 'core/memoizer';
function deleteRow(idx, activeCell, data, selectedRows) {
    const newProps = {
        data: R.remove(idx, 1, data)
    };
    if (R.is(Array, activeCell) && activeCell[0] === idx) {
        newProps.active_cell = [];
    }
    if (R.is(Array, selectedRows) && R.contains(idx, selectedRows)) {
        newProps.selected_rows = R.without([idx], selectedRows);
    }
    return newProps;
}
function rowSelectCell(idx, rowSelectable, selectedRows, setProps) {
    return (React.createElement("td", { key: 'select', className: 'dash-select-cell', style: { width: `30px`, maxWidth: `30px`, minWidth: `30px` } },
        React.createElement("input", { type: rowSelectable === 'single' ? 'radio' : 'checkbox', name: 'row-select', checked: R.contains(idx, selectedRows), onChange: () => setProps({
                selected_rows: rowSelectable === 'single' ?
                    [idx] :
                    R.ifElse(R.contains(idx), R.without([idx]), R.append(idx))(selectedRows)
            }) })));
}
function rowDeleteCell(setProps, deleteFn) {
    return (React.createElement("td", { key: 'delete', className: 'dash-delete-cell', onClick: () => setProps(deleteFn()), style: { width: `30px`, maxWidth: `30px`, minWidth: `30px` } }, '×'));
}
const getter = (activeCell, data, viewportData, viewportIndices, rowSelectable, rowDeletable, selectedRows, setProps) => R.addIndex(R.map)((_, rowIndex) => [
    ...(rowDeletable ? [rowDeleteCell(setProps, deleteRow.bind(undefined, viewportIndices[rowIndex], activeCell, data, selectedRows))] : []),
    ...(rowSelectable ? [rowSelectCell(viewportIndices[rowIndex], rowSelectable, selectedRows, setProps)] : [])
], viewportData);
export default memoizeOneFactory(getter);
