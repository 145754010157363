const __1day = 86400 * 1000;
const __20years = 86400 * 1000 * 365 * 20;
export default class CookieStorage {
    static delete(id, domain = '', path = '/') {
        let expires = new Date((new Date().getTime() - __1day)).toUTCString();
        document.cookie = `${id}=;expires=${expires};domain=${domain};path=${path}`;
    }
    static get(id) {
        if (!id.length) {
            return;
        }
        id = id.toLowerCase();
        let cookies = document.cookie.split(';').map(cookie => {
            let fragments = cookie.split('=');
            return {
                id: fragments[0].trim(),
                value: fragments[1]
            };
        });
        return (cookies.find(cookie => id === cookie.id.toLocaleLowerCase()) || {}).value;
    }
    static set(id, value, domain = '', path = '/') {
        let expires = new Date((new Date().getTime() + __20years)).toUTCString();
        let entry = `${id}=${value};expires=${expires};domain=${domain};path=${path}`;
        if (CookieStorage.get(id)) {
            CookieStorage.delete(id, domain, path);
        }
        document.cookie = entry;
    }
}
