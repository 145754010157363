import * as R from 'ramda';
import { memoizeOne } from 'core/memoizer';
import { ColumnType } from 'dash-table/components/Table/props';
const D3_DEFAULT_LOCALE = {
    symbol: ['$', ''],
    decimal: '.',
    group: ',',
    grouping: [3],
    percent: '%',
    separate_4digits: true
};
const DEFAULT_NULLY = '';
const DEFAULT_SPECIFIER = '';
const applyDefaultToLocale = memoizeOne((locale) => getLocale(locale));
const applyDefaultsToColumns = memoizeOne((defaultLocale, columns) => R.map(column => {
    const c = R.clone(column);
    if (c.type === ColumnType.Numeric && c.format) {
        c.format.locale = getLocale(defaultLocale, c.format.locale);
        c.format.nully = getNully(c.format.nully);
        c.format.specifier = getSpecifier(c.format.specifier);
    }
    return c;
}, columns));
export default (props) => {
    const locale_format = applyDefaultToLocale(props.locale_format);
    return R.mergeAll([
        props,
        {
            columns: applyDefaultsToColumns(locale_format, props.columns),
            locale_format
        }
    ]);
};
export const getLocale = (...locales) => R.mergeAll([
    D3_DEFAULT_LOCALE,
    ...locales
]);
export const getSpecifier = (specifier) => specifier === undefined ?
    DEFAULT_SPECIFIER :
    specifier;
export const getNully = (nully) => nully === undefined ?
    DEFAULT_NULLY :
    nully;
