import * as R from 'ramda';
import SyntaxTree from 'core/syntax-tree';
import { memoizeOneFactory } from 'core/memoizer';
import converter from './py2jsCssProperties';
import { ifColumnId, ifColumnType } from 'dash-table/conditional';
function convertElement(style) {
    const indexFilter = style.if && (style.if.header_index || style.if.row_index);
    let ast;
    return {
        matchesColumn: (column) => !style.if || (ifColumnId(style.if, column.id) &&
            ifColumnType(style.if, column.type)),
        matchesRow: (index) => indexFilter === undefined ?
            true :
            typeof indexFilter === 'number' ?
                index === indexFilter :
                indexFilter === 'odd' ? index % 2 === 1 : index % 2 === 0,
        matchesFilter: (datum) => !style.if ||
            style.if.filter === undefined ||
            (ast = ast || new SyntaxTree(style.if.filter)).evaluate(datum),
        style: convertStyle(style)
    };
}
function convertStyle(style) {
    return R.reduce((res, [key, value]) => {
        if (converter.has(key)) {
            res[converter.get(key)] = value;
        }
        return res;
    }, {}, R.toPairs(style));
}
export const derivedRelevantCellStyles = memoizeOneFactory((cell, dataCell, cells, dataCells) => R.concat(R.concat(cell ? [convertElement(cell)] : [], R.map(convertElement, cells || [])), R.concat(dataCell ? [convertElement(dataCell)] : [], R.map(convertElement, dataCells || []))));
export const derivedRelevantFilterStyles = memoizeOneFactory((cell, filter, cells, filters) => R.concat(R.concat(cell ? [convertElement(cell)] : [], R.map(convertElement, cells || [])), R.concat(filter ? [convertElement(filter)] : [], R.map(convertElement, filters || []))));
export const derivedRelevantHeaderStyles = memoizeOneFactory((cell, header, cells, headers) => R.concat(R.concat(cell ? [convertElement(cell)] : [], R.map(convertElement, cells || [])), R.concat(header ? [convertElement(header)] : [], R.map(convertElement, headers || []))));
export const derivedTableStyle = memoizeOneFactory((defaultTable, table) => [
    convertStyle(defaultTable),
    convertStyle(table)
]);
