export var ColumnType;
(function (ColumnType) {
    ColumnType["Any"] = "any";
    ColumnType["Numeric"] = "numeric";
    ColumnType["Text"] = "text";
    ColumnType["Datetime"] = "datetime";
})(ColumnType || (ColumnType = {}));
export var FilteringType;
(function (FilteringType) {
    FilteringType["Advanced"] = "advanced";
    FilteringType["Basic"] = "basic";
})(FilteringType || (FilteringType = {}));
export var ContentStyle;
(function (ContentStyle) {
    ContentStyle["Fit"] = "fit";
    ContentStyle["Grow"] = "grow";
})(ContentStyle || (ContentStyle = {}));
export var ChangeAction;
(function (ChangeAction) {
    ChangeAction["Coerce"] = "coerce";
    ChangeAction["None"] = "none";
    ChangeAction["Validate"] = "validate";
})(ChangeAction || (ChangeAction = {}));
export var ChangeFailure;
(function (ChangeFailure) {
    ChangeFailure["Default"] = "default";
    ChangeFailure["Accept"] = "accept";
    ChangeFailure["Reject"] = "reject";
})(ChangeFailure || (ChangeFailure = {}));
export var Presentation;
(function (Presentation) {
    Presentation["Dropdown"] = "dropdown";
    Presentation["Input"] = "input";
})(Presentation || (Presentation = {}));
