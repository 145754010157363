import * as R from 'ramda';
import React from 'react';
import { memoizeOne } from 'core/memoizer';
import memoizerCache from 'core/cache/memoizer';
import { Presentation } from 'dash-table/components/Table/props';
import Cell from 'dash-table/components/Cell';
import derivedCellEventHandlerProps, { Handler } from 'dash-table/derived/cell/eventHandlerProps';
import isActiveCell from 'dash-table/derived/cell/isActive';
import isSelectedCell from 'dash-table/derived/cell/isSelected';
export default (propsFn) => new Wrappers(propsFn).get;
class Wrappers {
    constructor(propsFn, handlers = derivedCellEventHandlerProps(propsFn)) {
        this.handlers = handlers;
        /**
         * Returns the wrapper for each cell in the table.
         */
        this.get = memoizeOne((activeCell, columns, data, offset, selectedCells) => R.addIndex(R.map)((_, rowIndex) => R.addIndex(R.map)((column, columnIndex) => {
            const active = isActiveCell(activeCell, rowIndex + offset.rows, columnIndex + offset.columns);
            const selected = isSelectedCell(selectedCells, rowIndex + offset.rows, columnIndex + offset.columns);
            const isDropdown = column.presentation === Presentation.Dropdown;
            const classes = 'dash-cell' +
                ` column-${columnIndex}` +
                (active ? ' focused' : '') +
                (selected ? ' cell--selected' : '') +
                (isDropdown ? ' dropdown' : '');
            return this.wrapper.get(rowIndex, columnIndex)(active, classes, columnIndex, column.id, rowIndex, this.handlers(Handler.Enter, rowIndex, columnIndex), this.handlers(Handler.Leave, rowIndex, columnIndex), this.handlers(Handler.Move, rowIndex, columnIndex));
        }, columns), data));
        /**
         * Returns the wrapper for a cell.
         */
        this.wrapper = memoizerCache()((active, classes, columnIndex, columnId, rowIndex, onEnter, onLeave, onMove) => (React.createElement(Cell, { active: active, attributes: {
                'data-dash-column': columnId,
                'data-dash-row': rowIndex
            }, classes: classes, key: `column-${columnIndex}`, onMouseEnter: onEnter, onMouseLeave: onLeave, onMouseMove: onMove })));
    }
}
