import React, { PureComponent } from 'react';
import { KEY_CODES, isNavKey } from 'dash-table/utils/unicode';
export default class CellInput extends PureComponent {
    constructor(props) {
        super(props);
        this.propagateChange = () => {
            if (this.state.value === this.props.value) {
                return;
            }
            const { onChange } = this.props;
            onChange(this.state.value);
        };
        this.handleChange = (e) => {
            this.setState({ value: e.target.value });
        };
        this.handleKeyDown = (e) => {
            const is_focused = this.props.focused;
            if (is_focused &&
                (e.keyCode !== KEY_CODES.TAB && e.keyCode !== KEY_CODES.ENTER)) {
                return;
            }
            if (!is_focused && !isNavKey(e.keyCode)) {
                return;
            }
            this.propagateChange();
        };
        this.state = {
            value: props.value
        };
    }
    render() {
        const { className, onClick, onDoubleClick, onMouseUp, onPaste, value } = this.props;
        // input does not handle `null` correct (causes console error)
        const sanitizedValue = this.state.value === null ?
            undefined :
            this.state.value;
        return (React.createElement("div", { className: 'dash-input-cell-value-container dash-cell-value-container' },
            React.createElement("div", { className: 'input-cell-value-shadow cell-value-shadow' }, value),
            React.createElement("input", { ref: 'textInput', type: 'text', className: className, onBlur: this.propagateChange, onChange: this.handleChange, onClick: onClick, onDoubleClick: onDoubleClick, onKeyDown: this.handleKeyDown, onMouseUp: onMouseUp, onPaste: onPaste, value: sanitizedValue })));
    }
    componentWillReceiveProps(nextProps) {
        const { value: nextValue } = nextProps;
        if (this.state.value !== nextValue) {
            this.setState({
                value: nextValue
            });
        }
    }
    componentDidUpdate() {
        this.setFocus();
    }
    componentDidMount() {
        this.setFocus();
    }
    setFocus() {
        const { active } = this.props;
        if (!active) {
            return;
        }
        const input = this.refs.textInput;
        if (input && document.activeElement !== input) {
            input.focus();
            input.setSelectionRange(0, input.value ? input.value.length : 0);
        }
    }
}
