import * as R from 'ramda';
import { memoizeOne } from 'core/memoizer';
import memoizerCache from 'core/cache/memoizer';
import SyntaxTree from 'core/syntax-tree';
const mapData = R.addIndex(R.map);
export default () => new Dropdowns().get;
class Dropdowns {
    constructor() {
        /**
         * Return the dropdown for each cell in the table.
         */
        this.get = memoizeOne((columns, data, indices, columnConditionalDropdown, columnStaticDropdown, dropdown_properties) => mapData((datum, rowIndex) => R.map(column => {
            const applicable = this.applicable.get(column.id, rowIndex)(column, indices[rowIndex], columnConditionalDropdown, columnStaticDropdown, dropdown_properties);
            return this.dropdown.get(column.id, rowIndex)(applicable, column, datum);
        }, columns), data));
        /**
         * Returns the list of applicable dropdowns for a cell.
         */
        this.applicable = memoizerCache()((column, realIndex, columnConditionalDropdown, columnStaticDropdown, dropdown_properties) => {
            let legacyDropdown = ((dropdown_properties &&
                dropdown_properties[column.id] &&
                (dropdown_properties[column.id].length > realIndex ?
                    dropdown_properties[column.id][realIndex] :
                    null)) || column).options;
            const conditional = columnConditionalDropdown.find((cs) => cs.id === column.id);
            const base = columnStaticDropdown.find((ss) => ss.id === column.id);
            return [
                legacyDropdown || (base && base.dropdown),
                (conditional && conditional.dropdowns) || []
            ];
        });
        /**
         * Returns the highest priority dropdown from the
         * applicable dropdowns.
         */
        this.dropdown = memoizerCache()((applicableDropdowns, column, datum) => {
            const [staticDropdown, conditionalDropdowns] = applicableDropdowns;
            const matches = [
                ...(staticDropdown ? [staticDropdown] : []),
                ...R.map(([cd]) => cd.dropdown, R.filter(([cd, i]) => this.evaluation.get(column.id, i)(this.ast.get(column.id, i)(cd.condition), datum), R.addIndex(R.map)((cd, i) => [cd, i], conditionalDropdowns)))
            ];
            return matches.length ? matches.slice(-1)[0] : undefined;
        });
        /**
         * Get the query's AST.
         */
        this.ast = memoizerCache()((query) => new SyntaxTree(query));
        /**
         * Evaluate if the query matches the cell's data.
         */
        this.evaluation = memoizerCache()((ast, datum) => ast.evaluate(datum));
    }
}
