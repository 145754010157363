import * as R from 'ramda';
import React from 'react';
import { memoizeOneFactory } from 'core/memoizer';
function rowSelectCell() {
    return (React.createElement("th", { key: 'select', className: 'expanded-row--empty-cell dash-select-header', style: { width: `30px`, maxWidth: `30px`, minWidth: `30px` } }));
}
function rowDeleteHeader() {
    return (React.createElement("th", { key: 'delete', className: 'expanded-row--empty-cell dash-delete-header', style: { width: `30px`, maxWidth: `30px`, minWidth: `30px` } }));
}
const getter = (headerRows, rowSelectable, rowDeletable) => R.addIndex(R.map)(() => [
    ...(rowDeletable ? [rowDeleteHeader()] : []),
    ...(rowSelectable ? [rowSelectCell()] : [])
], R.range(0, headerRows));
export default memoizeOneFactory(getter);
