import { DebugLevel, LogLevel } from 'core/Logger';
import CookieStorage from 'core/storage/Cookie';
const DASH_DEBUG = 'dash_debug';
const DASH_LOG = 'dash_log';
export default class Environment {
    static get searchParams() {
        return (typeof URL !== 'undefined' &&
            URL.prototype &&
            URL.prototype.constructor &&
            new URL(window.location.href).searchParams) || { get: () => null };
    }
    static get debugLevel() {
        const debug = this.searchParams.get(DASH_DEBUG) || CookieStorage.get(DASH_DEBUG);
        return debug ?
            DebugLevel[debug] || DebugLevel.NONE :
            DebugLevel.NONE;
    }
    static get logLevel() {
        const log = this.searchParams.get(DASH_LOG) || CookieStorage.get(DASH_LOG);
        return log ?
            LogLevel[log] || LogLevel.ERROR :
            LogLevel.ERROR;
    }
}
