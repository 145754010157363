export const reconcileNull = (value, options) => {
    const allowNull = Boolean(options && options.validation && options.validation.allow_null);
    const nully = isNully(value);
    return {
        success: nully && allowNull,
        value: nully ? null : value
    };
};
export const isNully = (value) => value === undefined ||
    value === null ||
    (typeof value === 'number' &&
        (isNaN(value) || !isFinite(value)));
